<template>
    <!-- 付款倒计时 -->
    <div class="pay-tiem" v-if="orderStatus === '5'">
      <div class="pay-tiem-text">{{ t('orderDetails.payPending') }}</div>
      <div class="pay-tiem-tiem">
        <span class="tiem pay-h">{{showCountdownInfo.h}}</span>
        <span class="colon">:</span>
        <span class="tiem pay-m">{{showCountdownInfo.m}}</span>
        <span class="colon">:</span>
        <span class="tiem pay-s">{{showCountdownInfo.s}}</span>
      </div>
    </div>
    <Load :loading="loading" v-if="loading"/>
    <region-wrapper :class="`${orderStatus === '5' && 'wrapper'}`" v-else>
      <item-header :title="t('orderDetails.orderDetails')"/>
      <div class="orders-detail-content">
          <h2 class="orders-status">
              <span>{{t('orderDetails.p1')}} </span>
              <span class="status">{{status[orderStatus]}}</span>
          </h2>
          <div class="order-date">
              <span class="date">{{t('orderDetails.p2')}} {{orderDate(addressList.orderOn)}}</span>
              <span class="order-number"># {{orderNumber}}</span>
              <button class="logistics-btn" @click="goLogistice" v-if="orderStatus === '2'">{{t('orderDetails.p3')}} </button>
              <a-button type="primary" class="pay-submit" @click="onSubmitOrder" :loading="submitOrderLoading" v-if="orderStatus === '5'">{{ t('orderDetails.submitOrder') }}</a-button>
          </div>
          <div class="order-good" v-for="item in goodsList" :key="item.skuId">
              <router-link :to="`/goods/${item.goodsId}?s=${item.skuId}`" class="img">
                  <img :src="`${item.goodsPic}?x-oss-process=image/resize,w_110,h_110`" alt="order good">
              </router-link>
              <div class="good-message">
                  <h3 class="good-name">{{item.goodsName}}</h3>
                  <div class="good-message-details">{{skuAttrFunc(item.attr)}}</div>
              </div>
              <div class="good-quantity">Qty: {{item.quantity}}</div>
              <div class="money">
                  <span class="good-money">{{t('global.currency')}}{{item.goodsPrice}}</span>
                  <span class="good-market-money">{{t('global.currency')}}{{item.goodsMarketPrice}}</span>
              </div>
          </div>
          <div class="order-message">
              <div class="message-item">
                  <h3>{{t('orderDetails.p4')}} </h3>
                  <p>{{addressList.firstName}} {{addressList.lastName}}</p>
                  <p>{{addressList.address}}</p>
                  <p>{{addressList.city}}</p>
                  <p>{{addressList.StatusOrProvince}}</p>
                  <p>{{addressList.countryOrRegion}}</p>
                  <p>{{addressList.phoneNumber}}</p>
                  <p>{{addressList.email}}</p>
              </div>
              <div class="message-item">
                  <h3>{{t('orderDetails.p5')}} </h3>
                  <p class="paypal">{{addressList.paymentMethod}}</p>
              </div>
              <div class="message-item">
                  <h3>{{t('orderDetails.p6')}} </h3>
                  <div class="summary">
                      <div class="summary-item">
                          <div>{{t('orderDetails.p7')}} </div>
                          <div>{{t('orderDetails.p8')}} </div>
                          <div>{{t('orderDetails.p9')}} </div>
                          <div>{{t('orderDetails.p10')}} </div>
                      </div>
                      <div class="summary-item">
                          <div>{{t('global.currency')}}{{addressList.subTotal}}</div>
                          <div>{{t('global.currency')}}0.00</div>
                          <div>{{t('global.currency')}}0.00</div>
                          <div>{{t('global.currency')}}{{addressList.orderTotal}}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </region-wrapper>
</template>
<script lang='ts'>
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import importComponents from '@/utils/import-components';
import {
  computed,
  defineComponent, onBeforeMount, onUnmounted, reactive, ref, watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Cookies from 'js-cookie';
import { getOrderDetailAddress, getOrderDetailGoods } from '@/services/user';
import type { OrderDetailAddress, OrderDetailGoods } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';
import { addTransferOrder } from '@/services/pay';
import { Button, message } from 'ant-design-vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { availableLocales } from '@/i18n';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: 'OrdersDetails',
  components: {
    ItemHeader,
    RegionWrapper,
    Load,
    ...importComponents(
      Button,
    ),
  },
  setup() {
    const { t, locale } = useI18n();
    const loading = ref(true);
    const router = useRouter();
    const route = useRoute();
    const submitOrderLoading = ref(false);
    const status = {
      // 1: 'Prepared',
      // 2: 'Delivered',
      // 3: 'Finished',
      0: t('myOrders.closed'), // 已关闭
      1: t('myOrders.prepared'), // 待发货
      2: t('myOrders.delivered'), // 已发货
      3: t('myOrders.completed'), // 已完成
      4: t('myOrders.obligation'), // 其他待付款
      5: t('myOrders.obligation'), // 转账的待付款
    };
    // 拿订单号和订单日期
    const orderNumber = route.fullPath.split('?')[1];
    const orderDate = computed(() => (date: string) => {
      const arr = date.split(' ');
      return `${arr[0]} ${arr[1]}${arr[2] === 'ago' ? '' : ','} ${arr[2]}`;
    });

    const goodsList: OrderDetailGoods[] = reactive([]);
    const addressList: OrderDetailAddress[] = reactive([]);

    // 创建订单时间
    const orderStatus = ref('');
    const orderCreationTime = ref<dayjs.Dayjs>();
    // 倒计时时间显示
    const showCountdownInfo = reactive({
      h: '00',
      m: '00',
      s: '00',
    });
    // 倒计时定时器
    const payTimeInterval = ref(0);
    // 监听当关闭的时候清除定时器
    watch(orderStatus, () => {
      if (orderStatus.value === '0') {
        clearInterval(payTimeInterval.value);
      }
    });
    // 支付倒计时
    const countdown = (createdTime: dayjs.Dayjs) => {
      payTimeInterval.value = setInterval(() => {
        const nowTime = dayjs();
        // 一个小时到期：
        const validTime = 1000 * 60 * 60;
        // 当前时间和创建时间的时间差：
        if (nowTime.isSameOrAfter(createdTime)) {
          orderStatus.value = '0';
          return;
        }
        // let differ = (nowTime) - (+createdTime);
        // // 时间差超过 时间期限时，或者小于0（bug），则返回0
        // if (differ > validTime) {
        //   orderStatus.value = '0';
        //   return;
        // }
        // 还剩多少时间：
        const differ = createdTime.diff(nowTime);
        // differ = validTime - differ;
        // 计算时
        const h = Math.floor(differ / (60 * 60 * 1000));
        // 计算分
        const m = Math.floor(differ / (60 * 1000));
        // 计算秒
        const s = Math.floor((differ - m * (60 * 1000)) / 1000);
        // showCountdownInfo.h = h < 10 ? `0${h}` : `${h}`;
        showCountdownInfo.m = `${m}`.padStart(2, '0');
        showCountdownInfo.s = `${s}`.padStart(2, '0');
      }, 1000);
    };

    // 进入页面请求订单详情的两个接口--订单商品&订单地址信息
    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        try {
          const goodsRes = await getOrderDetailGoods({ orderNumber });
          const addressRes = await getOrderDetailAddress({ orderNumber });
          if (goodsRes.success && addressRes.success) {
            Object.assign(goodsList, goodsRes.data);
            Object.assign(addressList, addressRes.data);
            orderStatus.value = addressRes.data.orderStatus;
            const now = dayjs
              .tz(
                addressRes.data.createdAt,
                availableLocales[locale.value].timezone,
              )
              .tz(dayjs.tz.guess())
              .add(1, 'hours');
            orderCreationTime.value = now;
            // 请求成功loading效果消失
            loading.value = false;
            if (orderStatus.value === '5') {
              countdown(now);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    });
    onUnmounted(() => {
      clearInterval(payTimeInterval.value);
    });

    // 加工sku显示文案
    const skuAttrFunc = (sku: string) => {
      const arr = sku.split(',').reverse().join('， ');
      return arr;
    };

    // 去物流页
    const goLogistice = () => {
      router.push(`/user/logistics?${orderNumber}`);
    };
    // 转账待支付提交
    const onSubmitOrder = async () => {
      submitOrderLoading.value = true;
      // 提交订单成功之后跳到订单完成页
      const res = await addTransferOrder({ orderNumber });
      if (res.success) {
        clearInterval(payTimeInterval.value);
        router.push(`/order-complete/${orderNumber}`);
        submitOrderLoading.value = false;
      } else {
        message.error('Submit Order Error!');
        setTimeout(() => { submitOrderLoading.value = false; }, 500);
      }
    };
    return {
      goLogistice,
      goodsList,
      orderNumber,
      addressList,
      status,
      orderDate,
      skuAttrFunc,
      loading,
      t,
      onSubmitOrder,
      submitOrderLoading,
      showCountdownInfo,
      orderStatus,
    };
  },
});
</script>

<style lang='scss' scoped>
@import '@/assets/styles/mixin.scss';
@import '@/assets/styles/variables.scss';
  .pay-tiem{
    position: fixed;
    background: #17E3C8;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    .pay-tiem-text{
      padding-left: 16px;
      padding-right: 70px;
      font-size: 22px;
      font-family: Inter-SemiBold, Inter;
      font-weight: 600;
      color: #FFFFFF;
    }
    .pay-tiem-tiem{
      padding-right: 16px;
      padding-left: 70px;
      color: #FFFFFF;
      .colon{
        padding: 0 2px;
        font-size: 9px;
      }
      .tiem{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        height: 39px;
        background: #FFFFFF;
        border-radius: 3px;
        font-size: 15px;
        font-family: Poppins-Bold, Poppins;
        font-weight: bold;
        color: #223263;
      }
    }
  }
    :deep(header) {
        h2{
            text-align: left;
            margin: 30px 30px 17px;
        }
    }
    .wrapper{
      margin-top: 80px;
    }
    .orders-detail-content{
        width: 100%;
        > div{
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
        }
        .orders-status{
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: $font-color;
        line-height: 24px;
        margin-bottom:13px;
        .status{
            color:$theme-color;
        }
        }
        .order-date{
            width: 100%;
            align-items: flex-end;
            padding-bottom: 37px;
            border-bottom: 1px solid #E1E1E1;

            > span{
            font-size: 18px;
            font-weight: $font-weight;
            color: $font-color-weak;
            line-height: 16px;
            }
            .order-number{
                margin-right: auto;
                margin-left: 67px;
            }
            .logistics-btn{
                width: 124px;
                height: 36px;
                background: $theme-color;
                border-radius: 4px;
                border: none;
                font-size: 18px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 21px;
                cursor: pointer;
                &:focus{
                    border: none;
                    outline: none;
                }
            }
            .pay-submit{
              background: #EA4F52;
              border-radius: 4px;
              height: 36px;
              width: 164px;
              color: #FFFFFF;
              font-size: 18px;
              font-family: Inter-Medium, Inter;
              font-weight: 500;
            }
        }
        .order-good{
            padding-bottom: 30px;
            padding-top: 30px;
            align-items: center;
            border-bottom: 1px solid #E1E1E1;
            .img{
                margin-right: 89px;
                width: 130px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    max-width: 100%;
                }
            }
               .good-message{
                   width: 433px;
               }
               .good-quantity{
                   width: 233px;
               }
               .money{
                   width: 433px;
                   text-align: right;
               }
           .good-message{
               .good-name{
                font-size: 18px;
                font-weight: 600;
                color: $font-color;
                line-height: 21px;
               }
               .good-message-details{
                font-size: 14px;
                font-weight: 500;
                color: $font-color-weak;
                line-height: 26px;
               }
           }

           .good-quantity{
               margin: 0 auto;
               font-size: 14px;
                line-height: 17px;
                font-weight: 500;
                text-align: center;
           }
             .good-money{
                font-size: 18px;
                font-weight: 800;
                color: $theme-color;
                line-height: 21px;
                padding-right: 6px;
            }
            .good-market-money{
                font-size: 14px;
                font-weight: 600;
                color: #969696;
                margin-left: 6px;
                line-height: 21px;
                text-decoration: line-through;
            }
        }
        .order-message{
            padding-top: 34px;
            padding-bottom: 30px;

        .message-item{
            h3{
            font-size: 18px;
            font-weight: 600;
            color: $font-color;
            line-height: 21px;
            margin-bottom: 11px;
            text-align: left;
            }
            &:last-child{
                h3{
                    text-align: center;
                }
            }
            p{
            font-size: 14px;
            font-weight: 500;
            color: $font-color-weak;
            line-height: 27px;
            margin: 0;
                &.paypal{
                line-height: 18px;
            }
            }
            .summary{
            font-size: 14px;
            font-weight: 500;
            color: $font-color-weak;
            line-height: 27px;
            display: flex;
            justify-content: space-between;
            .summary-item{
                line-height: 17px;
                &:first-child{
                    margin-right: 50px;
                    :last-child{
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
                &:last-child{
                    flex: 1;
                }
                > div{
                    white-space: nowrap;
                    text-align: right;
                     margin-bottom: 16px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
            }
        }
        }
    }
</style>
