
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import importComponents from '@/utils/import-components';
import {
  computed,
  defineComponent, onBeforeMount, onUnmounted, reactive, ref, watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Cookies from 'js-cookie';
import { getOrderDetailAddress, getOrderDetailGoods } from '@/services/user';
import type { OrderDetailAddress, OrderDetailGoods } from '@/services/interface/response';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';
import { addTransferOrder } from '@/services/pay';
import { Button, message } from 'ant-design-vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { availableLocales } from '@/i18n';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: 'OrdersDetails',
  components: {
    ItemHeader,
    RegionWrapper,
    Load,
    ...importComponents(
      Button,
    ),
  },
  setup() {
    const { t, locale } = useI18n();
    const loading = ref(true);
    const router = useRouter();
    const route = useRoute();
    const submitOrderLoading = ref(false);
    const status = {
      // 1: 'Prepared',
      // 2: 'Delivered',
      // 3: 'Finished',
      0: t('myOrders.closed'), // 已关闭
      1: t('myOrders.prepared'), // 待发货
      2: t('myOrders.delivered'), // 已发货
      3: t('myOrders.completed'), // 已完成
      4: t('myOrders.obligation'), // 其他待付款
      5: t('myOrders.obligation'), // 转账的待付款
    };
    // 拿订单号和订单日期
    const orderNumber = route.fullPath.split('?')[1];
    const orderDate = computed(() => (date: string) => {
      const arr = date.split(' ');
      return `${arr[0]} ${arr[1]}${arr[2] === 'ago' ? '' : ','} ${arr[2]}`;
    });

    const goodsList: OrderDetailGoods[] = reactive([]);
    const addressList: OrderDetailAddress[] = reactive([]);

    // 创建订单时间
    const orderStatus = ref('');
    const orderCreationTime = ref<dayjs.Dayjs>();
    // 倒计时时间显示
    const showCountdownInfo = reactive({
      h: '00',
      m: '00',
      s: '00',
    });
    // 倒计时定时器
    const payTimeInterval = ref(0);
    // 监听当关闭的时候清除定时器
    watch(orderStatus, () => {
      if (orderStatus.value === '0') {
        clearInterval(payTimeInterval.value);
      }
    });
    // 支付倒计时
    const countdown = (createdTime: dayjs.Dayjs) => {
      payTimeInterval.value = setInterval(() => {
        const nowTime = dayjs();
        // 一个小时到期：
        const validTime = 1000 * 60 * 60;
        // 当前时间和创建时间的时间差：
        if (nowTime.isSameOrAfter(createdTime)) {
          orderStatus.value = '0';
          return;
        }
        // let differ = (nowTime) - (+createdTime);
        // // 时间差超过 时间期限时，或者小于0（bug），则返回0
        // if (differ > validTime) {
        //   orderStatus.value = '0';
        //   return;
        // }
        // 还剩多少时间：
        const differ = createdTime.diff(nowTime);
        // differ = validTime - differ;
        // 计算时
        const h = Math.floor(differ / (60 * 60 * 1000));
        // 计算分
        const m = Math.floor(differ / (60 * 1000));
        // 计算秒
        const s = Math.floor((differ - m * (60 * 1000)) / 1000);
        // showCountdownInfo.h = h < 10 ? `0${h}` : `${h}`;
        showCountdownInfo.m = `${m}`.padStart(2, '0');
        showCountdownInfo.s = `${s}`.padStart(2, '0');
      }, 1000);
    };

    // 进入页面请求订单详情的两个接口--订单商品&订单地址信息
    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        try {
          const goodsRes = await getOrderDetailGoods({ orderNumber });
          const addressRes = await getOrderDetailAddress({ orderNumber });
          if (goodsRes.success && addressRes.success) {
            Object.assign(goodsList, goodsRes.data);
            Object.assign(addressList, addressRes.data);
            orderStatus.value = addressRes.data.orderStatus;
            const now = dayjs
              .tz(
                addressRes.data.createdAt,
                availableLocales[locale.value].timezone,
              )
              .tz(dayjs.tz.guess())
              .add(1, 'hours');
            orderCreationTime.value = now;
            // 请求成功loading效果消失
            loading.value = false;
            if (orderStatus.value === '5') {
              countdown(now);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    });
    onUnmounted(() => {
      clearInterval(payTimeInterval.value);
    });

    // 加工sku显示文案
    const skuAttrFunc = (sku: string) => {
      const arr = sku.split(',').reverse().join('， ');
      return arr;
    };

    // 去物流页
    const goLogistice = () => {
      router.push(`/user/logistics?${orderNumber}`);
    };
    // 转账待支付提交
    const onSubmitOrder = async () => {
      submitOrderLoading.value = true;
      // 提交订单成功之后跳到订单完成页
      const res = await addTransferOrder({ orderNumber });
      if (res.success) {
        clearInterval(payTimeInterval.value);
        router.push(`/order-complete/${orderNumber}`);
        submitOrderLoading.value = false;
      } else {
        message.error('Submit Order Error!');
        setTimeout(() => { submitOrderLoading.value = false; }, 500);
      }
    };
    return {
      goLogistice,
      goodsList,
      orderNumber,
      addressList,
      status,
      orderDate,
      skuAttrFunc,
      loading,
      t,
      onSubmitOrder,
      submitOrderLoading,
      showCountdownInfo,
      orderStatus,
    };
  },
});
